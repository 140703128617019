/**
 * Concatenates functions
 *
 * @param fns
 * @returns {*|(function(...[*]): *)}
 */
export const compose = (...fns) => fns.reduce((f, g) => (...args) => f(g(...args)));

/**
 * Inverted compose for readability
 *
 * @param fns
 * @returns {*}
 */
export const pipe = (...fns) => compose.apply(compose, fns.reverse());
