import {pipe} from "../modules/functions";

const GA_CAMPAIGN_PARAMETER = {
    'utm_source'  : 'source',  // Campaign Source
    'utm_medium'  : 'medium',  // Campaign Medium
    'utm_campaign': 'name',    // Campaign Name
    'utm_term'    : 'term',    // Campaign Keyword
    'utm_content' : 'content', // Campaign Content
    'wt_mc'       : 'id'
};
const SPRINGER_TRACKING_PARAMETER = '?wt_mc';

/***
 * Extracts Springer Google Analytics tracking parameters from the current URI.
 *
 * @returns {Array}
 */
const getGaParameters = () => pipe(
    a => a.substr(1).split("&").map(b => b.split("=")),
    a => a.map(b => b.map(decodeURIComponent)),
    a => a.filter(b => GA_CAMPAIGN_PARAMETER.hasOwnProperty(b[0]))
)(document.location.search);

/**
 * Checks whether the requested URL is a Springer Google Analytics campaign.
 *
 * @returns {boolean}
 */
const isGaCampaign = () => document.location.search.substr(0, SPRINGER_TRACKING_PARAMETER.length) === SPRINGER_TRACKING_PARAMETER;

/**
 * Performs Google Analytics campaign tracking when the page is viewed.
 */
psiac.gAnalytics = function() {
    console.log('calling analytics');
    if (!isGaCampaign()) return;

    const parameterArray = getGaParameters();
    const campaign = parameterArray.reduce((obj, param) => Object.assign(obj, {[GA_CAMPAIGN_PARAMETER[param[0]]]: param[1]}), {});

    dataLayer.push({
        'event': 'datalayer-update', // required - do not rename this value
        "session": {
            "attributes": {
                "campaign": campaign
            }
        }
    });

    if (!!(window.history && history.pushState)) {
        history.pushState("", "", document.location.origin + document.location.pathname);
    }
};

psiac.gtmScripts = function() {
    console.log('calling gtm scripts');
    let dataLayer = [{
        "user": [{}],
        "session": {},
        "page": {},
        "content": {},
        "privacy": {},
        "version": "1.0.0",
    }];

    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth={{ google_tag_manager_auth_token }}&gtm_preview={{ google_tag_manager_environment }}&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-WVJHFMN');
};
